#contact{
    width: 90%; /* Adjust the width as needed */
  margin: 0 auto;
}

.align-items-center{
  
    padding-top: 30px;

}

.contact-form h2{
    text-align: center;
}

.contact-form-1{
    display: flex;
    gap: 2.5rem;
    justify-content: space-around;
}

.contact-form-1 img{
    width: 30%;
    max-width: 100%;
    max-height: 100%;
}

.title-form{
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    font-family: 'Cormorant Infant', serif;
    font-size: 2rem;
    color:#482e1d;
    width: 50%;
}

form{
    display: flex;
    
    flex-direction: column;
    justify-content: center;
}

input{
    width: 100%;
    height: 3.5rem;
    background: #fefaf7 ;
    border: none;
    border-radius: 5px;
    margin-bottom: 10px;
    font-family: 'Gothic A1', sans-serif;
    
}

input::placeholder{
color:#482e1d;
opacity: 50%;
}

textarea{
    width: 100%;
    height: 25rem;
    background: #fefaf7 ;
    resize: none;
    border: none;
    border-radius: 5px;
    margin-bottom: 10px;
    font-family: 'Gothic A1', sans-serif;
    
}

textarea::placeholder{
    color:#482e1d;
    opacity: 50%;
    }

button{
    background-color: #e2c7b6;
    border: 1px double white;
    border-radius: 5px;
    font-family: 'Gothic A1', sans-serif;
    color:#482e1d;

}