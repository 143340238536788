.skill-container{
    display: flex;
    justify-content: center;
    align-items: center;
    gap:1rem;
    height: fit-content;
    margin: 25px auto;
    width: 85vw;
    left:0;right:0;
    
}

.section-title{
    font-family: 'Cormorant Infant', serif;
    font-weight: bold;
    color: #482e1d;
    text-align: center;
}
#skill-section{
    font-family: 'Cormorant Infant', serif;
    font-weight: bold;
    font-size: clamp(1rem, 2vw + .1rem, 11rem);
    text-align: center;
    width:65%;
}

#skills-1{
    margin-bottom: 0;
   
}
#skills-2{
    font-family: 'Gothic A1', sans-serif;
    font-weight: 400;
    width: 70%;
    font-size: .5em;
    margin: 0 16px;
    text-align: left;
    color:#895d2b
}

.skill-frame{
    width: 100%;
    position: relative;
    align-items: center;
    z-index: -10; 
    margin-bottom: 10px;
}

.skill-top{
    position: absolute;
    width: 100%;
}
.skill-bottom{
    position: absolute;
    width: 100%;
    bottom:0;
}
.logo-container{
    position: relative;
    display: inline-block;
    margin: 0 auto;
    width: fit-content;
}

.fade-in-section is-visible{
    display: inline-block;
    width: fit-content; 
}

.logo{
   
    overflow: hidden;
    z-index: 1;
    margin: 4em auto;
    top: 50%;
    left: 50%;
    flex: 1 0 21%;
    text-align: center;
   
}

.logo img{
    max-width: 14%; 
    background: rgba(255, 255, 255, 0.606);
    border-radius: 50%;
    padding: .25em;
    margin: .5em;
}

@media (max-width:500px){
    .logo img{
        max-width: 12.25%;
    }
}

