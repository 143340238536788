
.first{
    display: flex;
    flex-direction: row;
    position: relative;
    justify-content: space-around;
    gap: 7%;
    margin:4em auto;
    width: 75vw;
    left:0;right:0;
    
}

@media (max-width: 600px) {
    .first {
        margin: auto;
        margin-bottom: 2em;
    }
  }


.description{
    font-family: 'Gothic A1', sans-serif;
    word-spacing: .25em;
    border: .5px white;
    letter-spacing: -.05em;
    color: #895d2b;
    font-size: clamp(.5rem, 1.45vw + .1rem, 8rem);
    width: 55%;
}


#greeting{
    font-family: 'Cormorant Infant', serif;
    font-weight: 400;
    font-style: italic;
    color:#482e1d;
    font-size: 2.5em;
    margin: 70px 0px 0px 0px;
}

@media (max-width: 750px){
    #greeting{
        margin: 40px 0px 0px 0px;
    }
}

@media (max-width: 500px){
    #greeting{
        margin: 10px 0px 0px 0px;
    }
}

#desc{
    font-weight: 300;
    margin: 0;
    line-height: 1.6;
    
}

:root {
    --min-width: 100px; /* Set the minimum width */
    --max-width: 400px; /* Set the maximum width */
  }

  .painting {
    position: relative;
    display: inline-block;
    width: var(--max-width);
    overflow: hidden;
    transition: max-width 1s ease;
}

.painting-img-container {
    position: relative;
    width: 100%;
}

.painting img {
    width: 100%;
    height: auto;
}

#words {
    font-family: 'Cormorant Infant', serif;
    font-weight: 600;
    font-size: clamp(.25rem, 1.45vw + .25rem, 8rem);
    color: #2b276b;
    position: absolute;
    bottom: 61%; /* Adjust the bottom position as needed */
    left: 46%;
    transform: translateX(-50%);
    white-space: nowrap;
}

.link-container {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.link-logo {
    display: inline-block;
    border-radius: 60px;
    background-color: rgba(255, 255, 255, 0.834);
    padding: 0.5em 0.6em;
    margin: 0 10px;
}

@media (max-width: 600px) {
    #words {
        font-size: clamp(0.25rem, 1.3vw + 0.25rem, 8rem);
       
    }
}
