

// @media screen and (max-width: 1000px) {
//     #top1 {
//       margin-bottom: -300px; /* Change to a smaller margin for screens narrower than 768px */
//     }
//   }
  
//   /* Further adjustments for even smaller screens if needed */
//   @media screen and (max-width: 480px) {
//     .element {
//       margin-bottom: 5px; /* Change to an even smaller margin for screens narrower than 480px */
//     }
//   }
.content{
    position:absolute;
    transform: translate(50px, 50px);
}

