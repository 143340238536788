#gallery-wrapper{
    width: 40vw;
    height: 100%;
    transition: width .1s ease;
    
}
#gallery-frame img{
    width: 100%;
}

#gallery-frame{
    position: relative;
    padding: 10px;
    border: 4.75px double rgb(248, 244, 241);
}



.section_container{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    height: fit-content;
    
    
}
.sub-divide{
    display:flex;
    margin:15px;
    margin-top: 0;
    top:10px;
    align-items: center;
    justify-content: center;
    
}
.sub-divide img{
    width: 250px;
    height: auto;
    max-width: 50vw;
}



.arrow {
    background: none;
	color: inherit;
	border: none;
	padding: 0;
    margin-left: 5px;
	font: inherit;
	cursor: pointer;
	outline: inherit;
    transition: 0.4s ease-in-out;
   

    img {
        width: auto;
        height: 10px;
    }

}

#code-arrow:hover {
    transform: translateX(10%);
}


#gallery-nav-container{
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 15px 0;
}

.dots-container {
    display: flex;
    height: max-content;
    justify-content: center;
    align-items: center;
    gap: .04rem; /* Adjust the gap between dots as needed */
}
.dot {
    width: 0;
    height: 0;
    border: 7.5px solid transparent;
    border-bottom-color: #dfa02a;
    position: relative;
    top: -7px;
    margin: 0 4px;
    cursor: pointer;
  }
  
  .dot:after {
    content: '';
    position: absolute;
    left: -7.5px;
    top: 7px;
    width: 0;
    height: 0;
    border: 7.5px solid transparent;
    border-top-color: #dfa02a;
  }
  
  .active {
    width: 0;
    height: 0;
    border: 8px solid transparent; /* Adjust the size as needed */
    border-bottom: 14px solid #7F4A00; /* Use border-bottom for the bottom border */
    position: relative;
    top: -8px; /* Adjust the top position to center it vertically */
  }
  
  .active:after {
    content: '';
    position: absolute;
    left: -8px;
    top: 14px;
    width: 0;
    height: 0;
    border: 8px solid transparent; /* Adjust the size as needed */
    border-top: 14px solid #7F4A00; /* Use border-top for the top border */
  }

  
.section-title{
    font-size: 2.5vw;
}

#proj-title{
    margin-top: 20px;
    margin-bottom: 0;
}



.project{
    position: relative;
}

#project-background{
    position: absolute;
    top:20%; left:65%;
    width: 50%;
    opacity: 30%;
    pointer-events: none;
}

#project-background-2{
    position: absolute;
   top:20%; right:65%;
    width: 50%;
    opacity: 25%;
    pointer-events: none;
} 

.project-container{
    display: flex;
    gap:2rem;
    width:80vw;
    margin: 30px;
    font-size: clamp(.5rem, 1.2vw + .2rem, 6rem);
    align-items: center;
}

#project-name{
    font-family: 'Cormorant Infant', serif;
    font-weight: 400;
    font-style: italic;
    font-size: 2em;
    border-radius: 5px;
    padding: 10px;
    margin-top: 0;
    color:#482e1d;
}

#project-description{
    font-family: 'Gothic A1', sans-serif;
    font-weight: 400;
    word-spacing: .25em;
    margin: 0;
    line-height: 1.6;
    color:#482e1d;
    
}

.code-section{
    font-family: 'Gothic A1', sans-serif;
    font-weight: 400;
    word-spacing: .25em;
    font-size: .7em;
    margin: 0;
    margin-top: 10px;
    line-height: 1.6;
    color:#482e1d;
}
  
@media (max-width: 600px) {
    .dot {
        border: 5px solid transparent;
        top: -5px;
        border-bottom-color: #dfa02a;
      }
      
      .dot:after {
        left: -5px;
        top: 5px;
        border: 5px solid transparent;
        border-top-color: #dfa02a;
      }
      
      .active {
        border: 5.33px solid transparent; 
        border-bottom: 9.33px  solid #7F4A00; 
        top: -5.33px; 
      }
      
      .active:after {
        left: -5.2px;
        top: 8.8px;
        border: 5.33px solid transparent; 
        border-top: 9.33px solid #7F4A00;
      }
      
      #slides{
        width: 60%;
    }

    #gallery-wrapper{
        width: 45vw;
        
    }

    #gallery-frame{
        padding: 5px;
        border: 2.6px double rgb(248, 244, 241);
    }

    #project-name{
        padding: 0px 10px 0px 10px;
    }

    .arrow img {
            height: 5px;
        }
    
  }