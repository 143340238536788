.fade-in-section {
    opacity: 0;
    
    visibility: hidden;
    transition: opacity 1s ease-out;
    will-change: opacity, visibility;
  }
  .fade-in-section.is-visible {
    opacity: 1;
    visibility: visible;
  }
  