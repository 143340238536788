$primary-color: #ffd700;

@import 'animate.css';

// @font-face {
//   font-family: 'Helvetica Neue';
//   src: url('./assets/fonts/helvetica-neu.ttf') format('ttf');
// }
body{
  height: 100vh;
}
.App {
  text-align: center;

}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.full-body{
  height: auto;
  overflow-y:scroll;
}
html.lenis {
  height: auto;
}

.lenis.lenis-smooth {
  scroll-behavior: auto;
}

.lenis.lenis-smooth [data-lenis-prevent] {
  overscroll-behavior: contain;
}

.lenis.lenis-stopped {
  overflow: hidden;
}

.lenis.lenis-scrolling iframe {
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

// .card{
//   margin-top: -500px;
//   transition: margin-top 1s ease;

//   @media (max-width: 1100px){
//     margin-top: -440px;
// }
// @media (max-width: 950px){
//   margin-top: -400px;
// } 
// @media (max-width: 600px){
//     margin-top: 0px;
// }
// }

#top {
  position: sticky;
  top: 0;
  width: 100%;
  min-width: 100%;
  height: fit-content;
  object-fit: cover;
  z-index: 1; /* Set a higher z-index value */
  pointer-events: none;
  
}

#top1 {
 
  display: block;
  z-index: -1; /* Set a lower z-index value */
  transition: margin-bottom 0.5s ease;
  
}



.divide2{
  display: flex;
  margin:0 auto;
  align-items: center;
  flex-direction: column;
  background-image: radial-gradient(#fffbf9,#f9f2ec, #f9f2ec);
}
.divide2 img{
  width: 40%;
  height: auto;
 

}

.divide3{
  display: flex;
  margin:0 auto;
  align-items: center;
  flex-direction: column;
  
}
.divide3 img{
  width: 50%;
  height: auto;
}

.alternate-2{
  background-color: #e9d6c6;
}

.alternate-3{
  background-color: #ede0d1;
  margin-bottom: 0;
}

footer{
  text-align: end;
}
