
@import url('https://fonts.googleapis.com/css2?family=Cormorant+Infant:ital,wght@0,300;0,600;1,400&family=Cormorant:wght@300&family=Gothic+A1:wght@100;300;400;500&family=Hanken+Grotesk:wght@100&display=swap');

.container{
    width:100%
}
.title{
    
    font-family: 'Cormorant Infant', serif;
    font-weight: 300;
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items:center;
    text-align: center;
    height: 200px;
    margin-top: 70px;
    margin-bottom: 10px;
    color: #482e1d;
    font-size: clamp(.25rem, 3.75vw + .25rem, 7.5rem);
    transition: margin-top 1s ease, height 1s ease;

    @media (max-width: 1200px){
        margin-top: 25px;
    }

    @media (max-width: 600px){
        margin-top: 0px;
        height: 150px;
    }
}

#name{
    text-transform: uppercase;
    letter-spacing: -.1em;
    word-spacing: .1em;
    line-height: 0.9em;
    margin-bottom: 0;
}
#name::first-letter{
    font-size: 1.3em;
    letter-spacing: -.2em;
}
.seventh-letter {
    font-size: 1.3em;
    letter-spacing: -.1em;
  }

  .divide{
    display:flex;
    margin:15px;
    top:10px;
    align-items:center;
    
}
.divide img{
    width: 650px;
    height: auto;
    max-width: 50vw;
}

